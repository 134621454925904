import { getUserFetchInfo } from '@api/fetch-support/client';
import { LocalStorage } from '@mentimeter/storage';
import type { TrackingPayload } from './payload';
import { getTrackingURL } from '@api/fetch-support';
import { getDevelopmentFlag } from '@mentimeter/development-flags';

// To not have a dependency on http-clients
interface UserTokenLocalStorage {
  token: string;
}

export function trackUser(payload: TrackingPayload) {
  const { region } = getUserFetchInfo() ?? { region: null };
  const userTrackingToken =
    LocalStorage.getJSONItem<UserTokenLocalStorage>('user')?.token;
  if (!userTrackingToken || !region) {
    logOnTest(`No user tracking token or region found. Skipping tracking.`);
    return;
  }

  const trackingUrl = getTrackingURL(region);

  const encodedData = toBase64String(payload);
  const trackURL = `${trackingUrl}/event/user?data=${encodedData}&token=${userTrackingToken}`;

  const config: RequestInit = {
    method: 'POST',
  };

  logOnTest(`tracking user:`, JSON.stringify(payload, null, 4));
  try {
    if ('sendBeacon' in navigator) {
      navigator.sendBeacon(trackURL);
    } else {
      // No await, here happens in 0,3% of cases as of July 2024, best effort fetch
      fetch(`${trackURL}&missingBeacon=true`, config);
    }
  } catch (err) {
    logOnTest(`Error sending tracking event: ${err}`);
    // Swallow tracking errors
  }
}

function logOnTest(...logParams: string[]) {
  try {
    if (
      localStorage.getItem('console-tracking') === 'true' ||
      getDevelopmentFlag('console-tracking') === 'on'
    ) {
      // eslint-disable-next-line
      console.log(logParams);
    }
  } catch (error) {
    // localStorage is prone to throwing errors, but we dont care about them:
    // this logging block is for testing only
  }
}

function toBase64String(data: Record<string, any>) {
  return Buffer.from(JSON.stringify(data)).toString('base64');
}
