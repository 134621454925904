/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import type { AppState } from './types';

export const AppStateContext = React.createContext<AppState | null>(null);
AppStateContext.displayName = 'AppStateContext';

export const useAppState = (): AppState => {
  const context = React.useContext(AppStateContext);
  if (context === null) {
    throw new Error('Cannot use appState outside of the provider');
  }
  return context;
};

export function AppStateConsumer({
  children,
}: {
  children: (arg0: AppState | null) => React.ReactNode;
}) {
  return (
    <AppStateContext.Consumer>
      {(context) => children(context)}
    </AppStateContext.Consumer>
  );
}
