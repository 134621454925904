import { LocalStorage } from '@mentimeter/storage';

const SESSION_TOKEN_KEY = 'session_token';
const SESSION_REGION_KEY = 'session_region';

export interface UserFetchInfo {
  sessionToken: string;
  region: 'eu' | 'us';
}

/**
 * Retrieves user authentication information from localStorage (client-side).
 * Returns null if either session token or region is missing.
 */
export function getUserFetchInfo(): UserFetchInfo | null {
  const token = LocalStorage.getItem(SESSION_TOKEN_KEY);
  const region = LocalStorage.getItem(SESSION_REGION_KEY);

  if (!token || !region) {
    return null;
  }

  if (region !== 'eu' && region !== 'us') {
    throw new Error('Invalid region set in user authentication data.');
  }

  return {
    sessionToken: token,
    region: region,
  };
}
